<template>
	<div :title="symbol.name" v-if="symbol" class="animated symbol d-flex flex-column justify-content-center align-items-center text-center"
			 :class="classes">
	  <div class="symbol-id" v-text="symbol.name"></div>
	  <!--
<div class="animated symbol text-center" :class="classes">
	<div class="symbol-label text-uppercase text-truncate text-nowrap" v-text="`ID: ${symbol.id}`" :title="symbol.name"></div>
</div>
	-->
	  <div v-if="extraName" class="symbol-extra-label text-uppercase" v-text="extraName" :title="extraName"></div>
	</div>
</template>

<script>
export default {
	props: {
		symbol: {
			type: Object,
			default() {
				return {
					id: null,
					name: null
				}
			}
		},
		config: {
			type: Object,
			default: null
		},
		compact: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		extraName() {
			if (this.symbol) {
				let extra = []

				if (this.config && this.config.paytable.wilds.includes(this.symbol.id)) {
					extra.push("WILD")
				}

				return (extra.length > 0 ? `(${extra.join(' - ')})` : null)
			}
			return null
		},
		classes() {
			let c = []
			if (this.symbol) {
				c = [`symbol-${this.symbol.id}`]
			}
			if (this.compact) {
				c.push('compact')
			}
			return c
		},
	}
}
</script>

<style scoped>
.compact {
	font-size: 8px;
}
</style>
