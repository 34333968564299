<template>
	<div>
		<div v-if="prizes.length > 0" class="text-center">
			<div class="font-weight-bold" v-text="prizes.join(' + ')"></div>
			<div class="text-muted text-small" v-text="strategy"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		prize: {
			type: Object,
			default: () => ({})
		},
		displayStrategy: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		strategy() {
			if (this.displayStrategy && this.prize.strategy) {
				return this.prize.strategy
			} else {
				return ""
			}
		},
		prizes() {
			let ret = [];

			if (this.prize) {
				if (this.prize.multiplier > 0) {
					ret.push(`x${this.prize.multiplier}`)
				}

				if (this.prize.freespins > 0) {
					ret.push(`${this.prize.freespins} freespins`)
				}

				if (this.prize.jackpot) {
					ret.push(`JACKPOT: ${this.prize.jackpot}`)
				}

				if (this.prize.extra) {
					this.prize.extra.forEach(p => {
						if (p.value > 0) {
							ret.push(`${p.type} x${p.value}`)
						}
					})
				}
			}

			return ret
		}
	}
}
</script>
